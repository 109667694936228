




































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { set } from "date-fns";
import { mapActions } from "vuex";
import Icon from "@/components/elements/Icon.vue";
import RoosterBar from "@/components/parts/RoosterBar.vue";
import { coreApi, reserveerApi } from "@/lib/backend";
import type { AppointmentSlotViewModel } from "@/lib/backend/core.api";
import { DAYS, DAYS_LABEL } from "@/lib/backend/helpers";
import PencilSquare from "@/lib/icons/micro/pencil-square.vue";
import Trash from "@/lib/icons/micro/trash.vue";
import { buttonVariants } from "@/lib/pfg/components/button";
import { labelVariants } from "@/lib/pfg/components/label";
import { useGyms } from "@/lib/query/hooks/useGyms";
import { useRoosterStore } from "@/pinia/rooster";

export default defineComponent({
	name: "CoachMomenten",
	components: { Icon, RoosterBar, Trash, PencilSquare },
	setup() {
		const roosterStore = useRoosterStore();

		const gymId = ref<"all" | number>("all");

		const { data: roosters } = useQuery({
			queryKey: ["roosters"] as const,
			queryFn: async (context) =>
				await reserveerApi.roosters
					.getRoosters({ signal: context.signal })
					.then((response) => response.data),
			initialData: [] as Awaited<ReturnType<typeof reserveerApi.roosters.getRoosters>>["data"],
		});

		const rooster = computed(() =>
			roosters.value.find((rooster) => rooster.id === roosterStore.id),
		);

		const queryKeyRoosterAll = computed(() => {
			return [
				"roosters",
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				roosterStore.id,
				"all",
			] as const;
		});
		const enabledRoosterAll = computed(() => !!queryKeyRoosterAll.value[1]);
		const { data: allRooster } = useQuery({
			queryKey: queryKeyRoosterAll,
			queryFn: async (context) => {
				const [, scheduleId] = context.queryKey;

				if (!scheduleId) {
					return [];
				}

				return await coreApi.schedule
					.scheduleControllerGetBySchedule(scheduleId, undefined, { signal: context.signal })
					.then((response) => response.data);
			},
			initialData: [] as Awaited<
				ReturnType<typeof coreApi.schedule.scheduleControllerGetBySchedule>
			>["data"],
			select: (data) =>
				data.sort((a, b) => {
					const daySort = DAYS.indexOf(a.day) - DAYS.indexOf(b.day);

					if (daySort !== 0) {
						return daySort;
					}

					// If days are the same, sort based on `time` which is `HH:MM:SS`
					const aTime = a.time.split(":").map(Number);
					const bTime = b.time.split(":").map(Number);

					const hourSort = aTime[0] - bTime[0];

					if (hourSort !== 0) {
						return hourSort;
					}

					const minuteSort = aTime[1] - bTime[1];

					if (minuteSort !== 0) {
						return minuteSort;
					}

					return aTime[2] - bTime[2];
				}),
			enabled: enabledRoosterAll,
		});

		const queryKeyRoosterGym = computed(() => {
			return [
				"roosters",
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				roosterStore.id,
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				gymId.value!,
			] as const;
		});
		const enabledRoosterGym = computed(
			() =>
				!!queryKeyRoosterGym.value[1] &&
				!!queryKeyRoosterGym.value[2] &&
				queryKeyRoosterGym.value[2] !== "all",
		);
		const { data: gymRooster } = useQuery({
			queryKey: queryKeyRoosterGym,
			queryFn: async (context) => {
				const [, scheduleId, gymId] = context.queryKey;

				if (!scheduleId || !gymId || gymId === "all") {
					return [];
				}

				return await coreApi.schedule
					.scheduleControllerGetBySchedule(scheduleId, { gymId }, { signal: context.signal })
					.then((response) => response.data);
			},
			initialData: [] as Awaited<
				ReturnType<typeof coreApi.schedule.scheduleControllerGetBySchedule>
			>["data"],
			select: (data) =>
				data.sort((a, b) => {
					const daySort = DAYS.indexOf(a.day) - DAYS.indexOf(b.day);

					if (daySort !== 0) {
						return daySort;
					}

					// If days are the same, sort based on `time` which is `HH:MM:SS`
					const aTime = a.time.split(":").map(Number);
					const bTime = b.time.split(":").map(Number);

					const hourSort = aTime[0] - bTime[0];

					if (hourSort !== 0) {
						return hourSort;
					}

					const minuteSort = aTime[1] - bTime[1];

					if (minuteSort !== 0) {
						return minuteSort;
					}

					return aTime[2] - bTime[2];
				}),
			enabled: enabledRoosterGym,
		});

		const { data: gyms } = useGyms();

		function getGymName(id: number) {
			return gyms.value.find((gym) => gym.id === id)?.naam;
		}

		function formatRange(slot: Pick<AppointmentSlotViewModel, "duration" | "time">) {
			const formatter = new Intl.DateTimeFormat("nl", { timeStyle: "short" });

			// eslint-disable-next-line
			// @ts-ignore - Intl is not correctly typed within compiler TS
			return formatter.formatRange(
				set(new Date(), {
					hours: Number(slot.time.split(":")[0]),
					minutes: Number(slot.time.split(":")[1]),
					seconds: Number(slot.time.split(":")[2]),
				}),
				set(new Date(), {
					hours: Number(slot.time.split(":")[0]),
					minutes: Number(slot.time.split(":")[1]) + slot.duration,
					seconds: Number(slot.time.split(":")[2]),
				}),
			);
		}

		return {
			buttonVariants,
			labelVariants,
			DAYS_LABEL,
			set,
			roosterStore,
			rooster,
			allRooster,
			gymRooster,
			gyms,
			gymId,
			getGymName,
			formatRange,
		};
	},
	methods: {
		...mapActions("overlay", ["openOverlay"]),
		...mapActions("modal", ["openModal"]),
	},
});
